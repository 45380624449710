<script setup lang="ts">
import { useField } from 'vee-validate';

// Props
const modelValue = defineModel<boolean>();

const props = defineProps<{
  name: string;
  label: string;
  disabled?: boolean;
}>();

// Form
const { value, setValue } = useField<boolean>(props.name, undefined, {
  initialValue: modelValue.value,
  valueProp: modelValue.value,
});

// Lifecycle
watch(
  () => modelValue.value,
  (newValue) => setValue(newValue),
);
</script>
<template>
  <label class="flex flex-row gap-2 items-center justify-start h-[20px]" :class="[disabled ? 'cursor-not-allowed' : 'cursor-pointer']">
    <switch-root
      v-model="modelValue"
      :disabled="disabled"
      :class="[disabled ? '' : 'hover:bg-gray-50 ']"
      class="flex w-[30px] h-[18px] data-[state=unchecked]:bg-gray-200 data-[state=checked]:bg-green-900 rounded-full relative transition-[background]"
    >
      <switch-thumb
        :class="[value ? 'bg-primary-500' : 'bg-white']"
        class="w-3.5 h-3.5 my-auto bg-white text-xs rounded-full transition-transform translate-x-[2px] will-change-transform data-[state=checked]:translate-x-full"
      />
    </switch-root>
    <span class="select-none mr-2 text-sm" :class="[disabled ? 'text-gray-400' : 'text-gray-900']">{{ props.label }}</span>
  </label>
</template>
